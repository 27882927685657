export default {
  welcome: "Welcome to FiberxForm!",
  login: "Login",
  description: "Create forms easily with drag and drop interface.",
  welcomeBack: "Welcome back! Please enter your details.",
  loginContinue: "Log in to continue",
  forms: "Forms",
  make: "Make",
  maker: "Make",
  to: "to",
  and: "and",
  password: "Password",
  yourPass: "Your password",
  yourMail: "Your Email",
  rememberMe: "Remember Me",
  forgotPassword: "Forgot Password?",
  dontAccount: "Don't have an account?",
  signUp: "Sign Up",
  createAcc: "Create an account",
  createYouAcc: "Create your account",
  timeCreateAcc: "It's quick and easy.",
  onlyInviteCreateAcc: "You need to be invited to register.",
  selectOption: "Select an option",
  index: {
    title: "Create your form in minutes and start using it.",
    description: "You can create forms without code and hassle. Just log in and start using it.",
    desc_1: "Create",
    desc_2: "in seconds",
    start: "Start",
    features: "Features",
    pricing: "Pricing",
    login: "Login",
    footer: {
      contact: "Contact",
      help: "Help",
      privacy: "Privacy",
      terms: "Terms"
    }
  },
  register: {
    name: "Name",
    yourName: "Your name",
    yourEmail: "Your Email",
    yourPass: "Your password",
    signUp: "Sign Up",
    alreadyAccount: "Already have an account?",
    login: "Login",
    createAcc: "Create an account",
    createYouAcc: "Create your account",
    hearAboutUs: "How did you hear about us?",
    passwordAgain: "Enter your password again",
    passwordConfirm: "Confirm your password",
    agreeTerms: "I agree to the",
    termsConditions: "Terms and Conditions",
    privacyPolicy: "Privacy Policy",
    ofWebsiteConfirm: "of the website and confirm them!"
  },
  makeFast: "Create your form in minutes.",
  form: {
    chooseBase: "Choose a base to start",
    iaPowered: "AI Powered",
    noTemplate: "Simple blank template",
    withTemplate: "From a template",
    genarate: "Generate Form",
    goBack: "Go Back",
    publish: "Publish",
    save: "Save Changes",
    undo: "Undo",
    redo: "Redo",
  },
  create: {
    about: {
      submissions: "About Submissions",
      submitButtonText: "Text of Submit Button",
      editableSubmissions: "Editable submissions",
      editableSubmissionsButtonText: "Text of editable submissions button",
      databaseSubmissionAction: "Database Submission Action",
      createNewRecord: "Create new record (default)",
      updateRecord: "Update Record (or create if no match)",
      propertiesToCheckOnUpdate: "Properties to check on update",
      postSubmissionAction: "Post Submission Action",
      showSuccessPage: "Show Success page",
      redirect: "Redirect",
      redirectUrl: "Redirect URL",
      allowUsersToFillAgain: "Allow users to fill the form again",
      textOfRestartButton: "Text of re-start button",
      textAfterSubmission: "Text After Submission",
    },
    name: "Information",
    title: "Form Title",
    description: "Description",
    tags: "Tags",
    visibility: "Visibility",
    copySettingsFrom: "Copy Settings From",
    importSettings: "Import Settings from another form",
    cancel: "Cancel",
    confirmCopy: "Confirm & Copy",
    helpTags: "To organize your forms (hidden to respondents)",
    helpVisibility: "Only public form will be accessible",
    placeholderTags: "Select Tag(s)",
    placeholderVisibility: "Select Visibility",
    placeholderCopyForm: "Choose a form",
    published: "Published",
  },
  formAccess: {
    name: "Form Access",
    password: "Form Password",
    passwordHelp: "Leave empty to disable password",
    closesAt: "Close form on a scheduled date",
    closesAtHelp: "Leave empty to keep the form open",
    closedText: "Closed form text",
    closedTextHelp: "This message will be shown when the form will be closed",
    maxSubmissionsCount: "Limit number of submissions",
    maxSubmissionsCountPlaceholder: "Max submissions",
    maxSubmissionsCountHelp: "Leave empty for unlimited submissions",
    maxSubmissionsReachedText: "Max Submissions reached text",
    maxSubmissionsReachedTextHelp: "This message will be shown when the form will have the maximum number of submissions",
  },
  formCustomCode: {
    customCode: "Custom Code",
    customCodeHelp: "Custom code cannot be previewed in our editor. Please test your code using your actual form page (save changes beforehand).",
    description: "The code will be injected in the head section of your form page.",
  },
  formCustomization: {
    name: "Customization",
    theme: "Form Theme",
    size: "Input Size",
    borderRadius: "Input Roundness",
    darkMode: "Dark Mode",
    darkModeHelp: "To see changes, save your form and open it",
    width: "Form Width",
    widthHelp: "Useful when embedding your form",
    coverPicture: "Cover Picture",
    coverPictureHelp: "Not visible when form is embedded",
    logoPicture: "Logo",
    logoPictureHelp: "Not visible when form is embedded",
    color: "Color (for buttons & inputs border)",
    hideTitle: "Hide Title",
    noBranding: "Remove FiberxForm Branding",
    showProgressBar: "Show progress bar",
    uppercaseLabels: "Uppercase Input Labels",
    transparentBackground: "Transparent Background",
    transparentBackgroundHelp: "Only applies when form is embedded",
    confettiOnSubmission: "Confetti on successful submission",
    autoSave: "Auto save form response",
    autoSaveHelp: "Will save data in browser, if user not submit the form then next time will auto prefill last entered data",
    options: {
      default: "Default",
      simple: "Simple",
      notion: "Notion",
      small: "Small",
      medium: "Medium",
      large: "Large",
      none: "None",
      full: "Full",
      auto: "Auto - use Device System Preferences",
      light: "Light Mode",
      dark: "Dark Mode",
      centered: "Centered",
      fullWidth: "Full Width",
    },
  },
  formCustomSeo: {
    linkSettings: "Link Settings - SEO",
    description: "Customize the link, images and text that appear when you share your form on other sites (Open Graph).",
    formDomain: "Form Domain",
    formDomainPlaceholder: "yourdomain.com",
    pageTitle: "Page Title",
    pageTitleHelp: "Under or approximately 60 characters",
    pageDescription: "Page Description",
    pageDescriptionHelp: "Between 150 and 160 characters",
    pageThumbnail: "Page Thumbnail Image",
    pageThumbnailHelp: "Also know as og:image - 1200 X 630",
    pageFavicon: "Page Favicon Image",
    pageFaviconHelp: "Upload favicon image to be displayed on the form page",
  },
  formEditorPreview: {
    formPreview: "Form Preview",
    answersNotSaved: "Answers won't be saved",
    restartForm: "Restart Form",
  },
  formError: {
    close: "Close",
    msg: "Error saving your form",
  },
  formSecurityPrivacy: {
    name: "Security & Privacy",
    canBeIndexed: "Indexable by Google",
    canBeIndexedHelp: "If enabled, your form can appear in the search results of Google",
    useCaptcha: "Protect your form with a Captcha",
    useCaptchaHelp: "If enabled we will make sure respondent is a human",
  },
  formStructure: {
    name: "Form Structure",
  },
  formFieldsEditor: {
    addBlock: "Add block",
    removeBlock: "Remove block?",
    yes: "Yes",
    no: "No",
    hideFormTitle: "Hide Form Title",
    autoSubmitForm: "Auto Submit Form",
    autoSubmitFormHelp: "Form will auto submit immediate after open URL",
  },
  advancedFormUrlSettings: {
    showAdvancedSharingOptions: "Show advanced sharing options",
    hideFormTitle: "Hide Form Title",
    hideFormTitleHelp: "This option is disabled because the form title is already hidden",
    autoSubmitForm: "Auto Submit Form",
    autoSubmitFormHelp: "Form will auto submit immediate after open URL",
  },
  copyContent: {
    copiedDraft: "Copied! But other people won't be able to see the form since it's currently in draft mode",
    copied: "Copied!",
  },
  customFieldValidation: {
    validation: "Validation",
    addCustomValidation: "Add some custom validation (save form before testing)",
    conditionsForField: "Conditions for this field to be accepted",
    errorMessage: "Error message when validation fails",
  },
  fieldOptions: {
    general: "General",
    excludeOrMakeRequired: "Exclude this field or make it required.",
    required: "Required",
    hidden: "Hidden",
    disabled: "Disabled",
    checkbox: "Checkbox",
    advancedCheckboxOptions: "Advanced options for checkbox.",
    useToggleSwitch: "Use toggle switch",
    useToggleSwitchHelp: "If enabled, checkbox will be replaced with a toggle switch",
    customization: "Customization",
    changeFieldName: "Change your form field name, pre-fill a value, add hints, etc.",
    fieldName: "Field Name",
    hideFieldName: "Hide field name",
    useSimpleTextInput: "Use simple text input",
    prefilledValue: "Pre-filled value",
    prefilledWithCurrentDate: "Pre-filled with current date",
    prefilledFile: "Pre-filled file",
    emptyInputText: "Empty Input Text (Placeholder)",
    fieldWidth: "Field Width",
    fieldHelp: "Field Help",
    fieldHelpPosition: "Field Help Position",
    generatesUUID: "Generates a unique id",
    generatesUUIDHelp: "If you enable this, we will hide this field and fill it with a unique id (UUID format) on each new form submission",
    generatesAutoIncrementId: "Generates an auto-incremented id",
    generatesAutoIncrementIdHelp: "If you enable this, we will hide this field and fill it a unique incrementing number on each new form submission",
    advancedOptions: "Advanced Options",
    logicBlock: "Logic Block",
    customFieldValidation: "Custom Field Validation",
    options: {
      default: "Default",
      simple: "Simple",
      notion: "Notion",
      small: "Small",
      medium: "Medium",
      large: "Large",
      none: "None",
      full: "Full",
      auto: "Auto - use Device System Preferences",
      light: "Light Mode",
      dark: "Dark Mode",
      centered: "Centered",
      fullWidth: "Full Width",
    },
    fileUploads: "File uploads",
    allowMultipleFiles: "Allow multiple files",
    allowCameraUploads: "Allow Camera uploads",
    allowedFileTypes: "Allowed file types",
    allowedFileTypesHelp: "Comma separated values, leave blank to allow all file types",
    maxFileSize: "Maximum file size (in MB)",
    maxFileSizeHelp: "Set the maximum file size that can be uploaded",
    rating: "Rating",
    advancedOptionsForRating: "Advanced options for rating.",
    maxRatingValue: "Max rating value",
    scale: "Scale",
    advancedOptionsForScale: "Advanced options for scale.",
    minScaleValue: "Min scale value",
    maxScaleValue: "Max scale value",
    scaleStepsValue: "Scale steps value",
    slider: "Slider",
    advancedOptionsForSlider: "Advanced options for slider.",
    minSliderValue: "Min slider value",
    maxSliderValue: "Max slider value",
    sliderStepsValue: "Slider steps value",
    textOptions: "Text Options",
    keepItSimple: "Keep it simple or make it a multi-lines input.",
    multiLinesInput: "Multi-lines input",
    secretInput: "Secret input",
    secretInputHelp: "Hide input content with * for privacy",
    dateOptions: "Date Options",
    endDate: "End date",
    prefillWithToday: "Prefill with 'today'",
    disablePastDates: "Disable past dates",
    disableFutureDates: "Disable future dates",
    includeTime: "Include time",
    timezone: "Timezone",
    timezoneHelp: "Make sure to select the same timezone you're using in Notion. Leave blank otherwise.",
    timeFormat: "Time format",
    dateFormat: "Date format",
    selectOptions: "Select Options",
    advancedOptionsForSelect: "Advanced options for your select/multiselect fields.",
    setSelectionOptions: "Set selection options",
    addOneOptionPerLine: "Add one option per line",
    allowCreation: "Allow respondent to create new options",
    alwaysShowAllSelectOptions: "Always show all select options",
    alwaysShowAllSelectOptionsHelp: "Options won't be in a dropdown anymore, but will all be visible",
    disabledCountries: "Disabled countries",
    removeCountries: "Remove countries from the phone input",
    selectAll: "Select All",
    unselectAll: "Un-select All",
    maxCharLimit: "Max character limit",
    maxCharLimitHelp: "Maximum character limit of 2000",
    alwaysShowCharLimit: "Always show character limit",
  },
}
