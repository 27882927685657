export default {
  welcome: "Bem-vindo ao FiberxForm!",
  login: "Login",
  description: "Crie formulários facilmente com a interface arrastar e soltar.",
  forms: "Formulários",
  make: "Criar",
  maker: "Faça",
  to: "em",
  and: "e",
  password: "Senha",
  yourPass: "Sua senha",
  yourMail: "Seu E-mail",
  rememberMe: "Lembrar de mim",
  forgotPassword: "Esqueceu a senha?",
  loginContinue: "Entrar",
  dontAccount: "Não tem uma conta?",
  signUp: "Cadastrar-se",
  createAcc: "Crie uma conta",
  createYouAcc: "Crie sua conta",
  timeCreateAcc: "É rápido e fácil.",
  onlyInviteCreateAcc: "Você precisa ser convidado para se registrar.",
  selectOption: "Selecione uma opção",
  index: {
    title: "Crie seu formulário em poucos minutos e comece a usar.",
    description: "Você pode criar os formulários sem códigos e sem complicações. Basta fazer login e começar a usar.",
    desc_1: "Crie",
    desc_2: "em segundos",
    start: "Começar",
    features: "Recursos",
    pricing: "Preços",
    login: "Entrar",
    footer: {
      contact: "Contato",
      help: "Ajuda",
      privacy: "Privacidade",
      terms: "Termos"
    }
  },
  register: {
    name: "Nome",
    yourName: "Seu nome",
    yourEmail: "Seu E-mail",
    yourPass: "Sua senha",
    signUp: "Cadastrar-se",
    alreadyAccount: "Já tem uma conta?",
    login: "Entrar",
    createAcc: "Crie uma conta",
    createYouAcc: "Crie sua conta",
    hearAboutUs: "Como soube de nós?",
    passwordAgain: "Insira sua senha novamente",
    passwordConfirm: "Confirme sua senha",
    agreeTerms: "Eu concordo com os",
    termsConditions: "Termos e Condições",
    privacyPolicy: "Política de Privacidade",
    ofWebsiteConfirm: "do site e as confirmo!"
  },
  makeFast: "Crie seu formulário em poucos minutos.",
  welcomeBack: "Bem-vindo de volta! Por favor, insira seus detalhes.",
  form: {
    chooseBase: "Escolha uma base para começar",
    iaPowered: "Alimentado por IA",
    noTemplate: "Simples modelo em branco",
    withTemplate: "A partir de um template",
    genarate: "Gerar Form",
    goBack: "Voltar",
    publish: "Publicar",
    save: "Salvar",
    undo: "Desfazer",
    redo: "Refazer",
  },
  create: {
    about: {
      submissions: "Sobre Envio",
      submitButtonText: "Texto do Botão de Envio",
      editableSubmissions: "Envios Editáveis",
      editableSubmissionsButtonText: "Texto do Botão de Envios Editáveis",
      databaseSubmissionAction: "Ação de Envio para o Banco de Dados",
      createNewRecord: "Criar novo registro (padrão)",
      updateRecord: "Atualizar Registro (ou criar se não houver correspondência)",
      propertiesToCheckOnUpdate: "Propriedades a serem verificadas na atualização",
      postSubmissionAction: "Ação Pós-Envio",
      showSuccessPage: "Mostrar Página de Sucesso",
      redirect: "Redirecionar",
      redirectUrl: "URL de Redirecionamento",
      allowUsersToFillAgain: "Permitir que os usuários preencham o formulário novamente",
      textOfRestartButton: "Texto do Botão de Reinício",
      textAfterSubmission: "Texto Após o Envio",
    },
    name: "Informações",
    title: "Título do Formulário",
    description: "Descrição",
    tags: "Tags",
    visibility: "Visibilidade",
    copySettingsFrom: "Copiar Configurações De",
    importSettings: "Importar Configurações de Outro Formulário",
    cancel: "Cancelar",
    confirmCopy: "Confirmar e Copiar",
    helpTags: "Para organizar seus formulários (oculto para os respondentes)",
    helpVisibility: "Somente formulários públicos serão acessíveis",
    placeholderTags: "Selecione Tag(s)",
    placeholderVisibility: "Selecione Visibilidade",
    placeholderCopyForm: "Escolha um formulário",
    published: "Publicado",
  },
  formAccess: {
    name: "Acesso ao Formulário",
    password: "Senha do Formulário",
    passwordHelp: "Deixe em branco para desativar a senha",
    closesAt: "Fechar formulário em uma data agendada",
    closesAtHelp: "Deixe em branco para manter o formulário aberto",
    closedText: "Texto de Formulário Fechado",
    closedTextHelp: "Esta mensagem será exibida quando o formulário estiver fechado",
    maxSubmissionsCount: "Limitar número de envios",
    maxSubmissionsCountPlaceholder: "Máximo de envios",
    maxSubmissionsCountHelp: "Deixe em branco para envios ilimitados",
    maxSubmissionsReachedText: "Texto de Máximo de Envios Alcançado",
    maxSubmissionsReachedTextHelp: "Esta mensagem será exibida quando o formulário atingir o número máximo de envios",
  },
  formCustomCode: {
    customCode: "Código Personalizado",
    customCodeHelp: "O código personalizado não pode ser visualizado no nosso editor. Por favor, teste o código usando a página real do seu formulário (salve as alterações antes).",
    description: "O código será injetado na seção head da página do seu formulário.",
  },
  formCustomization: {
    name: "Personalização",
    theme: "Tema do Formulário",
    size: "Tamanho da Entrada",
    borderRadius: "Arredondamento da Entrada",
    darkMode: "Modo Escuro",
    darkModeHelp: "Para ver as alterações, salve seu formulário e abra-o",
    width: "Largura do Formulário",
    widthHelp: "Útil ao incorporar seu formulário",
    coverPicture: "Imagem de Capa",
    coverPictureHelp: "Não visível quando o formulário está incorporado",
    logoPicture: "Logotipo",
    logoPictureHelp: "Não visível quando o formulário está incorporado",
    color: "Cor (para botões e borda das entradas)",
    hideTitle: "Ocultar Título",
    noBranding: "Remover Branding da FiberxForm",
    showProgressBar: "Mostrar barra de progresso",
    uppercaseLabels: "Rótulos das Entradas em Maiúsculas",
    transparentBackground: "Fundo Transparente",
    transparentBackgroundHelp: "Aplica-se apenas quando o formulário está incorporado",
    confettiOnSubmission: "Confete ao envio bem-sucedido",
    autoSave: "Salvar automaticamente a resposta do formulário",
    autoSaveHelp: "Salva os dados no navegador; se o usuário não enviar o formulário, da próxima vez será preenchido automaticamente com os últimos dados inseridos",
    options: {
      default: "Padrão",
      simple: "Simples",
      notion: "Notion",
      small: "Pequeno",
      medium: "Médio",
      large: "Grande",
      none: "Nenhum",
      full: "Completo",
      auto: "Automático - usar Preferências do Sistema do Dispositivo",
      light: "Modo Claro",
      dark: "Modo Escuro",
      centered: "Centralizado",
      fullWidth: "Largura Completa",
    },
  },
  formCustomSeo: {
    linkSettings: "Configurações de Link - SEO",
    description: "Personalize o link, imagens e texto que aparecem quando você compartilha seu formulário em outros sites (Open Graph).",
    formDomain: "Domínio do Formulário",
    formDomainPlaceholder: "seudominio.com",
    pageTitle: "Título da Página",
    pageTitleHelp: "Menos de ou aproximadamente 60 caracteres",
    pageDescription: "Descrição da Página",
    pageDescriptionHelp: "Entre 150 e 160 caracteres",
    pageThumbnail: "Imagem em Miniatura da Página",
    pageThumbnailHelp: "Também conhecido como og:image - 1200 X 630",
    pageFavicon: "Imagem Favicon da Página",
    pageFaviconHelp: "Envie a imagem favicon para ser exibida na página do formulário",
  },
  formEditorPreview: {
    formPreview: "Pré-visualização do Formulário",
    answersNotSaved: "Respostas não serão salvas",
    restartForm: "Reiniciar Formulário",
  },
  formError: {
    close: "Fechar",
    msg: "Erro ao salvar seu formulário",
  },
  formSecurityPrivacy: {
    name: "Segurança e Privacidade",
    canBeIndexed: "Indexável pelo Google",
    canBeIndexedHelp: "Se ativado, seu formulário pode aparecer nos resultados de pesquisa do Google",
    useCaptcha: "Proteja seu formulário com um Captcha",
    useCaptchaHelp: "Se ativado, garantiremos que o respondente é humano",
  },
  formStructure: {
    name: "Estrutura do Formulário",
  },
  formFieldsEditor: {
    addBlock: "Adicionar bloco",
    removeBlock: "Remover bloco?",
    yes: "Sim",
    no: "Não",
    hideFormTitle: "Ocultar Título do Formulário",
    autoSubmitForm: "Auto Enviar Formulário",
    autoSubmitFormHelp: "O formulário será enviado automaticamente imediatamente após abrir a URL",
  },
  advancedFormUrlSettings: {
    showAdvancedSharingOptions: "Mostrar opções avançadas de compartilhamento",
    hideFormTitle: "Ocultar Título do Formulário",
    hideFormTitleHelp: "Esta opção está desativada porque o título do formulário já está oculto",
    autoSubmitForm: "Auto Enviar Formulário",
    autoSubmitFormHelp: "O formulário será enviado automaticamente imediatamente após abrir a URL",
  },
  copyContent: {
    copiedDraft: "Copiado! Mas outras pessoas não poderão ver o formulário porque está atualmente em modo de rascunho",
    copied: "Copiado!",
  },
  customFieldValidation: {
    validation: "Validação",
    addCustomValidation: "Adicione alguma validação personalizada (salve o formulário antes de testar)",
    conditionsForField: "Condições para que este campo seja aceito",
    errorMessage: "Mensagem de erro quando a validação falha",
  },
  fieldOptions: {
    general: "Geral",
    excludeOrMakeRequired: "Excluir este campo ou torná-lo obrigatório.",
    required: "Obrigatório",
    hidden: "Oculto",
    disabled: "Desativado",
    checkbox: "Caixa de Seleção",
    advancedCheckboxOptions: "Opções avançadas para a caixa de seleção.",
    useToggleSwitch: "Usar interruptor",
    useToggleSwitchHelp: "Se ativado, a caixa de seleção será substituída por um interruptor",
    customization: "Personalização",
    changeFieldName: "Altere o nome do campo do formulário, preencha com um valor, adicione dicas, etc.",
    fieldName: "Nome do Campo",
    hideFieldName: "Ocultar nome do campo",
    useSimpleTextInput: "Usar entrada de texto simples",
    prefilledValue: "Valor pré-preenchido",
    prefilledWithCurrentDate: "Pré-preenchido com a data atual",
    prefilledFile: "Arquivo pré-preenchido",
    emptyInputText: "Texto de Entrada Vazia (Placeholder)",
    fieldWidth: "Largura do Campo",
    fieldHelp: "Ajuda do Campo",
    fieldHelpPosition: "Posição da Ajuda do Campo",
    generatesUUID: "Gera um ID único",
    generatesUUIDHelp: "Se você ativar isso, ocultaremos este campo e o preencheremos com um ID único (formato UUID) em cada novo envio de formulário",
    generatesAutoIncrementId: "Gera um ID auto-incrementado",
    generatesAutoIncrementIdHelp: "Se você ativar isso, ocultaremos este campo e o preencheremos com um número incremental único em cada novo envio de formulário",
    advancedOptions: "Opções Avançadas",
    logicBlock: "Bloco de Lógica",
    customFieldValidation: "Validação Personalizada do Campo",
    options: {
      default: "Padrão",
      simple: "Simples",
      notion: "Notion",
      small: "Pequeno",
      medium: "Médio",
      large: "Grande",
      none: "Nenhum",
      full: "Completo",
      auto: "Automático - usar Preferências do Sistema do Dispositivo",
      light: "Modo Claro",
      dark: "Modo Escuro",
      centered: "Centralizado",
      fullWidth: "Largura Completa",
    },
    fileUploads: "Uploads de Arquivos",
    allowMultipleFiles: "Permitir múltiplos arquivos",
    allowCameraUploads: "Permitir uploads da câmera",
    allowedFileTypes: "Tipos de arquivo permitidos",
    allowedFileTypesHelp: "Valores separados por vírgula, deixe em branco para permitir todos os tipos de arquivo",
    maxFileSize: "Tamanho máximo do arquivo (em MB)",
    maxFileSizeHelp: "Defina o tamanho máximo do arquivo que pode ser enviado",
    rating: "Avaliação",
    advancedOptionsForRating: "Opções avançadas para avaliação.",
    maxRatingValue: "Valor máximo de avaliação",
    scale: "Escala",
    advancedOptionsForScale: "Opções avançadas para escala.",
    minScaleValue: "Valor mínimo da escala",
    maxScaleValue: "Valor máximo da escala",
    scaleStepsValue: "Valor dos passos da escala",
    slider: "Deslizante",
    advancedOptionsForSlider: "Opções avançadas para o deslizante.",
    minSliderValue: "Valor mínimo do deslizante",
    maxSliderValue: "Valor máximo do deslizante",
    sliderStepsValue: "Valor dos passos do deslizante",
    textOptions: "Opções de Texto",
    keepItSimple: "Mantenha simples ou faça uma entrada de várias linhas.",
    multiLinesInput: "Entrada de várias linhas",
    secretInput: "Entrada secreta",
    secretInputHelp: "Ocultar o conteúdo da entrada com * para privacidade",
    dateOptions: "Opções de Data",
    endDate: "Data de término",
    prefillWithToday: "Pré-preencher com 'hoje'",
    disablePastDates: "Desativar datas passadas",
    disableFutureDates: "Desativar datas futuras",
    includeTime: "Incluir hora",
    timezone: "Fuso horário",
    timezoneHelp: "Certifique-se de selecionar o mesmo fuso horário que você está usando no Notion. Deixe em branco caso contrário.",
    timeFormat: "Formato de hora",
    dateFormat: "Formato de data",
    selectOptions: "Opções de Seleção",
    advancedOptionsForSelect: "Opções avançadas para seus campos de seleção/multisseleção.",
    setSelectionOptions: "Definir opções de seleção",
    addOneOptionPerLine: "Adicione uma opção por linha",
    allowCreation: "Permitir que o respondente crie novas opções",
    alwaysShowAllSelectOptions: "Sempre mostrar todas as opções de seleção",
    alwaysShowAllSelectOptionsHelp: "As opções não estarão mais em um menu suspenso, mas todas serão visíveis",
    disabledCountries: "Países desativados",
    removeCountries: "Remover países da entrada de telefone",
    selectAll: "Selecionar tudo",
    unselectAll: "Desmarcar tudo",
    maxCharLimit: "Limite máximo de caracteres",
    maxCharLimitHelp: "Limite máximo de caracteres de 2000",
    alwaysShowCharLimit: "Sempre mostrar limite de caracteres",
  },
}
