import enUS from './lang/en-US';
import ptBR from './lang/pt-BR';
export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'pt',
    messages: {
        en: enUS,
        pt: ptBR
    }
}))
